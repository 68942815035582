import React, {FC, useCallback, useEffect, useRef, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {usePlayGames} from "../features/play_games/usePlayGames";
import {useUser} from "../features/user/useUser";
import {useAppDispatch} from "../store";
import {setGameSession} from "../features/play_games/playGamesSlice";
import {PageLayout} from "../components/oropuro_layout/PageLayout";
import {alpha, Box, Button, ButtonProps, Paper, Stack, useTheme} from "@mui/material";
import {useCommonMediaQueries} from "../features/common_funcs/mediaQueries/useCommonMediaQueries";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Typography from "@mui/material/Typography";
import {SectionDivider} from "../components/oropuro_layout/section_devider/SectionDevider";
import ProvidersIcon from "../components/common/icons/Providers";
import {ProvidersListSection} from "../components/oropuro_layout/providers_section/ProvidersListSection";
import {useLanguage} from "../features/localisation/useLanguage";
import {LatestWinningsTable} from "../components/latest_winnings/LatestWinningsTable";
import {getDeviceType} from "../features/common_funcs";
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import FullscreenIcon from '@mui/icons-material/Fullscreen';

export const GameLaunchPage: FC = () => {
    const {languagePack: {pack: {argentina: {common: lang}}}} = useLanguage()
    const theme = useTheme()
    const {portraitMode} = useCommonMediaQueries()
    const {user, refreshUserBalance} = useUser()
    const [justMounted, setJustMounted] = useState<boolean>(true)
    const {startGameSession, gameSession} = usePlayGames()
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const internalId = queryParams.get('internalId')
    const dispatch = useAppDispatch()
    const internalIdPrev = useRef<string|undefined|null>()
    const navigate = useNavigate();
    const deviceType = getDeviceType()
    const [fullPageMode, setFullPageMode] = useState<boolean>(deviceType === 'mobile')

    useEffect(() => {
        if (justMounted) {
            setJustMounted(false)
            if (gameSession.SessionUrl !== null) {
                dispatch(setGameSession({SessionId: null, SessionUrl: null}))
            }
        }
    }, [dispatch, gameSession.SessionUrl, justMounted]);

    useEffect(() => {
        if (internalId !== internalIdPrev.current) {
            internalIdPrev.current = internalId
            dispatch(setGameSession({SessionId: null, SessionUrl: null}))
            if (internalId !== null) {
                startGameSession(parseInt(internalId))
            }
        }
    }, [dispatch, internalId, startGameSession]);

    const goBackOrHome = useCallback(() => {
        refreshUserBalance()
        if (window.history.state && window.history.state.idx > 0) {
            navigate(-1);
        } else {
            navigate('/');
        }
    }, [navigate, refreshUserBalance])

    const commonButtonProps: ButtonProps = {
        variant: 'outlined',
        sx: {
            minWidth: '34px',
            height: '34px',
            px: theme.spacing(1),
            backgroundColor: alpha('#fff', 0.08),
            borderWidth: '1px',
            borderColor: alpha('#fff', 0.1),
            color: '#E4DCD5',
            '&:hover': {
                borderWidth: '1px',
            }
        }
    };

    const switchFullPageMode = () => {
        setFullPageMode(prevState => !prevState)
    }

    const gameMessageHandler = useCallback((event: MessageEvent) => {
        if (event.type === 'message') {
            if (event.data === 'GT:EXIT') {
                goBackOrHome();
            }
        }
    }, [goBackOrHome]);

    useEffect(() => {
        window.addEventListener("message", gameMessageHandler);

        return () => {
            window.removeEventListener("message", gameMessageHandler);
        };
    }, [gameMessageHandler]);

    return <PageLayout pageName={'Home'} carouselDisabled>
        <Stack width={'100%'}>
            <Box display={'flex'} justifyContent={'space-between'} py={portraitMode ? 0 : 2}>
                <Button {...commonButtonProps} onClick={goBackOrHome}>
                    <ArrowBackIosIcon sx={{mr: '-8px', fontSize: '16px'}}/>
                </Button>
                <Button {...commonButtonProps} onClick={switchFullPageMode}>
                    <FullscreenIcon/>
                </Button>
            </Box>

            {user !== null ? <Stack
                    sx={{
                        borderRadius: '20px',
                        overflow: 'hidden',
                        backgroundColor: '#101317'
                    }}
                >
                    <Box
                        sx={{backgroundColor: 'inherit'}}
                        left={0}
                        right={0}
                        top={0}
                        bottom={0}
                        position={fullPageMode ? 'fixed' : undefined}
                        width={'100%'}
                        height={fullPageMode ? undefined : portraitMode ? '75dvh' : '80dvh'}
                        zIndex={fullPageMode ? 1000 : undefined}
                    >
                        {fullPageMode && <Box
                            onClick={goBackOrHome}
                            borderRadius={'16px'}
                            position={'fixed'}
                            left={'20px'}
                            top={'20px'}
                            height={'40px'}
                            width={'40px'}
                            sx={{backgroundColor: alpha('#000', 0.3), cursor: 'pointer'}}
                            display={'flex'}
                            justifyContent={'center'}
                            alignItems={'center'}
                        >
                            <ArrowBackIosIcon sx={{mr: '-8px'}}/>
                        </Box>}
                        {fullPageMode && <Box
                            onClick={switchFullPageMode}
                            borderRadius={'16px'}
                            position={'fixed'}
                            right={'20px'}
                            top={'20px'}
                            height={'40px'}
                            width={'40px'}
                            sx={{backgroundColor: alpha('#000', 0.3), cursor: 'pointer'}}
                            display={'flex'}
                            justifyContent={'center'}
                            alignItems={'center'}
                        >
                            <FullscreenExitIcon/>
                        </Box>}
                        {gameSession.SessionUrl && <iframe
                            style={{
                                width: '100%',
                                height: '100%',
                                border: 'none',
                                margin: '0',
                                padding: '0',
                                zIndex: 1000
                            }}
                            src={gameSession.SessionUrl}
                            allowFullScreen={true}
                            allow="autoplay"
                            title={gameSession.SessionId ?? ''}
                        />}
                    </Box>
                    <Box p={2}>
                        <Typography>
                            <Box component={'span'} sx={theme => ({color: alpha(theme.palette.text.primary, 0.6)})}>
                                RTP:
                            </Box>
                            &nbsp;
                            97.45%
                        </Typography>
                    </Box>
                </Stack> :
                <Paper sx={{
                    width: '100%',
                    height: '50dvh',
                    p: 3,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <Typography variant={'h2'}>You need to be logged in</Typography>
                </Paper>}
        </Stack>
        <Box px={'8px'}>
            <SectionDivider
                icon={ProvidersIcon}
                title={lang.providers}
                showLeftRightButtons={false}
            />
        </Box>
        <Box px={'8px'} mb={'24px'}>
            <ProvidersListSection/>
        </Box>
        <Box px={'8px'} mb={'24px'}>
            <LatestWinningsTable/>
        </Box>
    </PageLayout>
}